import { environment } from "./../environments/environment";
import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  from,
  InMemoryCache,
} from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "./core/service/auth.service";

const uri = environment.graphql;

export function createApollo(
  httpLink: HttpLink,
  authService: AuthService
): ApolloClientOptions<any> {
  const authLink = new ApolloLink((operation, forward) => {
    const authToken = authService.getToken();

    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    }));

    return forward(operation);
  });
  // not working--> failed request is not forwarding
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.map(async ({ extensions, message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            extensions
          );
          if (extensions.code == "invalid-jwt") {
            // Do stuff for handle invalid JWT

            const newToken = await authService.refreshToken();
            const oldHeaders = operation.getContext().headers;
            const newHeaders = {
              ...oldHeaders,
              Authorization: `Bearer ${newToken}`,
            };
            operation.setContext({
              headers: newHeaders,
            });

            // location.reload();

            return forward(operation);

            // return new Observable((observer) => {
            //   authService
            //     .refreshToken()
            //     .then((accessToken) => {
            //       console.log(accessToken);
            //       const oldHeaders = operation.getContext().headers;
            //       operation.setContext(({ headers = {} }) => ({
            //         headers: {
            //           // Re-add old headers
            //           ...oldHeaders,
            //           // Switch out old access token for new one
            //           authorization: `Bearer ${accessToken}` || null,
            //         },
            //       }));
            //     })
            //     .then(() => {
            //       const subscriber = {
            //         next: observer.next.bind(observer),
            //         error: observer.error.bind(observer),
            //         complete: observer.complete.bind(observer),
            //       };

            //       // Retry last failed request
            //       forward(operation).subscribe(subscriber);
            //     })
            //     .catch((error) => {
            //       // No refresh or client token available, we force user to login
            //       observer.error(error);
            //     });
            // })
          }
        });

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  );

  const http = httpLink.create({ uri });

  return new ApolloClient({
    link: from([errorLink, authLink, http]),
    cache: new InMemoryCache(),
    credentials: "include",
  });
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}
