import { userData } from "./../../interfaces/userData.type";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  user: userData;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(data: any) {
    return this.http.post(`${environment.nodeUrl}auth/login`, data);
  }
  signUp(data: any) {
    return this.http.post(`${environment.nodeUrl}auth/signup`, data);
  }
  editUser(data: any) {
    return this.http.post(`${environment.nodeUrl}auth/edit`, data);
  }
  getRole(token: any) {
    return JSON.parse(atob(token.split(".")[1])).role;
  }
  getUser() {
    return <userData>JSON.parse(localStorage.getItem("user"));
  }

  getToken() {
    return localStorage.getItem("token");
  }

  logged() {
    this.user = <userData>JSON.parse(localStorage.getItem("user"));
    if (this.user) {
      return true;
    } else {
      return false;
    }
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  async refreshToken(): Promise<string> {
    const refreshToken = localStorage.getItem("refToken");
    const obs = this.http.post(`${environment.nodeUrl}auth/refresh-token`, {
      refreshToken,
    });

    const data: any = await lastValueFrom(obs);
    localStorage.removeItem("token");
    localStorage.removeItem("refToken");
    localStorage.setItem("token", data.accessToken);
    localStorage.setItem("refToken", data.refreshToken);
    return data.refreshToken;
  }
}
