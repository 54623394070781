import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "name",
})
export class NamePipe implements PipeTransform {
  transform(value: string): string {
    const name = value.split(".")[0];
    return name;
  }
}
