import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "role",
})
export class RolePipe implements PipeTransform {
  transform(value: string): string {
    const role = value.split(".")[2];
    return role;
  }
}
