import { Pipe, PipeTransform } from "@angular/core";
import { ParticipantMap } from "src/app/core/models/participant";

@Pipe({
  name: "candidate",
})
export class CandidatePipe implements PipeTransform {
  transform(value: ParticipantMap[]): ParticipantMap[] {
    return value.filter((p) => p.role === "candidate");
  }
}
