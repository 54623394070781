import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { NamePipe } from "./pipes/name.pipe";
import { RolePipe } from "./pipes/role.pipe";
import { UidPipe } from "./pipes/uid.pipe";
import { RoleConvertorPipe } from "./pipes/role-convertor.pipe";
import { CandidatePipe } from "./pipes/candidate.pipe";
@NgModule({
  declarations: [NamePipe, RolePipe, UidPipe, RoleConvertorPipe, CandidatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    FeatherIconsModule,
    NamePipe,
    RolePipe,
    UidPipe,
    RoleConvertorPipe,
    CandidatePipe,
  ],
})
export class SharedModule {}
