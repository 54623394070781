import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "roleConvertor",
})
export class RoleConvertorPipe implements PipeTransform {
  transform(value: string): string {
    const role = value.split("_").join(" ");
    return role.toUpperCase();
  }
}
