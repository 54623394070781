// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphql: "https://osce-middleware.b-tle.com/v1/graphql",
  // graphql: "https://middleware.studymedicosceexamapp.com/v1/graphql",

  // nodeUrl: "http://localhost:3040/",
  nodeUrl: "https://dev-osce-node.b-tle.com/",
  // aws
  accessKey: "AKIAYAJGMKAZWIDC7GWF",
  secretKey: "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",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
