import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "uid",
})
export class UidPipe implements PipeTransform {
  transform(value: string): string {
    const uid = value.split(".")[1];
    return uid;
  }
}
