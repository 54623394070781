import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  // {
  //   path: "",
  //   title: "MENUITEMS.MAIN.TEXT",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["All"],
  //   submenu: [],
  // },

  // admin Modules
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.CLIENT.DASHBOARD",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "airplay",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },

  // Common Modules
  {
    path: "",
    title: "Users",
    moduleName: "admin",
    iconType: "feather",
    icon: "user-check",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/all-users",
        title: "All Users",
        moduleName: "admin",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/add-user",
        title: "Add User",
        moduleName: "",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/authentication/forgot-password",
      //   title: "Forgot Password",
      //   moduleName: "authentication",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/authentication/locked",
      //   title: "Locked",
      //   moduleName: "authentication",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/authentication/page404",
      //   title: "404 - Not Found",
      //   moduleName: "authentication",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/authentication/page500",
      //   title: "500 - Server Error",
      //   moduleName: "authentication",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },

  {
    path: "",
    title: "Stations",
    moduleName: "multilevel",
    iconType: "feather",
    icon: "square",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/all-stations",
        title: "All Stations",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/add-station",
        title: "Add Station",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Rooms",
    moduleName: "dashboard",
    iconType: "feather",
    icon: "layout",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/all-rooms",
        title: "All Rooms",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/add-room",
        title: "Add Room",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Documents",
    moduleName: "",
    iconType: "feather",
    icon: "file",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/all-docs",
        title: "All Documents",
        moduleName: "",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/add-doc",
        title: "Add Document",
        moduleName: "",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Sessions",
    moduleName: "authentication",
    iconType: "feather",
    icon: "chevrons-down",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/all-sessions",
        title: "All Session",
        moduleName: "",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/add-session",
        title: "Add Session",
        moduleName: "",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
];
